import { graphql, Link } from "gatsby";
import { node } from "prop-types";
import React from "react";
import {
  GlobalOptions,
  LastThreePostsType,
  MenuItems,
  SEOProperties,
} from "../shared/interfaces/graphql.interface";
import { RespContainer } from "../styles/common";
import { parsePostDate } from "./blog";
import * as StylingBlog from "../styles/BlogPage/StylingBlog";
import * as StylingBlogPost from "../styles/BlogPostPage/StylingBlogPost";
import BlogSideBar from "../components/ReUsables/BlogSideBar";
import SectionEzDivisorNewsLetter from "../components/ReUsables/SectionEzDivisorNewsLetter";
import SectionLatestBlogPosts from "../components/ReUsables/SectionLatestBlogPosts";
import { BlogPosts } from "../styles/Reusables/StylingSectionLatestBlogPosts";
import { useWindowSize } from "../components/CustomHooks/useWindowSize";
import styled from "styled-components";
import { Devices } from "../styles/variables";
import ShareButtons from "../components/ReUsables/ShareButtons";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import SEO from "../components/ReUsables/SEO";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";

import "./post.scss";

interface PostDataProps {
  pageContext: {
    data: PostContext;
  };
}

interface PostContext {
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  globalOptions: GlobalOptions;
  lastThreePost: LastThreePostsType;
  title: string;
  content: string;
  post: PostType;
  acf: { seo: SEOProperties };
}

type PostType = {
  id: string;
  path: string;
  slug: string;
  title: string;
  content: string;
  excerpt: string;
  categories: [{ name: string; path: string }];
  date: string;
  featured_media: {
    localFile: any;
    alt_text: string;
  };
  tags?: [{ id: string; slug: string; path: string }];
};

const contact: React.FC<PostDataProps> = ({ pageContext, ...props }) => {
  let pageContextData: PostContext = pageContext.data;
  let { post } = pageContextData;

  const { width } = useWindowSize();
  return (
    <>
      <SEO seo={pageContextData.acf.seo} />
      <RespContainer>
        <StylingBlogPost.DesktopImageWrapper>
          <StylingBlogPost.DesktopImage
            image={getImage(post.featured_media.localFile) as IGatsbyImageData}
            alt={post.featured_media.alt_text}
          />
        </StylingBlogPost.DesktopImageWrapper>

        <StylingBlogPost.Title>{post.title}</StylingBlogPost.Title>

        <StylingBlogPost.DateAndCategories>
          <span>{parsePostDate(post.date)}</span>
          {width > 992 && <span> | </span>}
          <span>
            {post?.categories?.map((category, index) => (
              <React.Fragment key={`cat${index}`}>
                <StylingBlogPost.CategoryLink to={category.path}>
                  {category.name}
                </StylingBlogPost.CategoryLink>
                {index !== post.categories?.length - 1 && ", "}
              </React.Fragment>
            ))}
          </span>
        </StylingBlogPost.DateAndCategories>
      </RespContainer>

      <StylingBlogPost.MobileImageWrapper>
        <StylingBlogPost.MobileImage
          image={getImage(post.featured_media.localFile) as IGatsbyImageData}
          alt={post.featured_media.alt_text}
        />
      </StylingBlogPost.MobileImageWrapper>

      <RespContainer>
        <StylingBlog.Container>
          <StylingBlog.PostsWrapper>
            <StylingBlogPost.PostWrapper>
              <StylingBlogPost.Content
                dangerouslySetInnerHTML={{ __html: post.content }}
                className="post-content"
              />
              <StylingBlogPost.TagsAndShareWrapper>
                <StylingBlogPost.TagsWrapper>
                  {post?.tags && (
                    <>
                      <StylingBlogPost.TagsTitle>
                        TAGS:
                      </StylingBlogPost.TagsTitle>
                      {post?.tags?.map((tag, index) => (
                        <React.Fragment key={`tag${index}`}>
                          <StylingBlogPost.TagLink to={tag.path}>
                            {tag.slug}
                          </StylingBlogPost.TagLink>
                          {index !== post.tags?.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </StylingBlogPost.TagsWrapper>
                <StylingBlogPost.ShareButtonsWrapper>
                  <StylingBlogPost.ShareButtonsText>
                    SHARE:
                  </StylingBlogPost.ShareButtonsText>
                  <StylingBlogPost.ShareButtons>
                    <ShareButtons
                      url={`https://ez-digital.com/blog/${post.slug}`}
                      title={post.title}
                      size={20}
                    />
                  </StylingBlogPost.ShareButtons>
                </StylingBlogPost.ShareButtonsWrapper>
              </StylingBlogPost.TagsAndShareWrapper>
            </StylingBlogPost.PostWrapper>
          </StylingBlog.PostsWrapper>

          <StylingBlog.SidebarWrapper>
            <BlogSideBar data={pageContextData} />
          </StylingBlog.SidebarWrapper>
        </StylingBlog.Container>
      </RespContainer>

      {/* <SectionEzDivisorNewsLetter
        section={pageContextData.globalOptions.ez_divisor_newsletter}
      /> */}
      <CustomBreakLine />
      <SectionLatestBlogPosts lastThreePost={pageContextData.lastThreePost} />
    </>
  );
};

export default contact;

const CustomBreakLine = styled("div")`
  height: 4.75rem;
  width: 100%;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none;
  }
`;
