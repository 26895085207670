import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import styled from "styled-components";

interface ShareButtonsProps {
  url: string;
  title: string;
  size: number;
  round?: boolean;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({
  url,
  title,
  size,
  round,
}) => {
  return (
    <ShareButtonsWrapper>
      <ul>
        <li>
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={size} round={round} />
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={url} title={title}>
            <LinkedinIcon size={size} round={round} />
          </LinkedinShareButton>
        </li>
        <li>
          <FacebookShareButton url={url} quote={title}>
            <FacebookIcon size={size} round={round} />
          </FacebookShareButton>
        </li>
      </ul>
    </ShareButtonsWrapper>
  );
};

export default ShareButtons;

const ShareButtonsWrapper = styled("div")`
  ul {
    height: 24px;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin-left: 0.875rem;
    }
  }
`;
