import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { AppButton, AppButtonAnchor } from "../common";
import { Colors, Devices } from "../variables";

export const Container = styled("div")`
  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
  }
`;

export const PostWrapper = styled("div")``;

export const CategoryLink = styled(Link)`
  color: ${Colors.ezGray};
  border-bottom: 1px solid transparent;
  &:focus {
    border-bottom: 1px solid ${Colors.sharpBlue};
  }
  &:hover,
  &:focus {
    color: ${Colors.sharpBlue};
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }
`;

export const DateAndCategories = styled("span")`
  display: inline-block;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${Colors.ezGray};
  margin-bottom: 2.25rem;

  > span:first-child {
    display: block;
    text-align: center;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: 5rem;
    text-align: center;

    > span:first-child {
      display: inline-block;
    }
  }
`;

export const Title = styled("h1")`
  font-size: 2.25rem;
  text-align: center;
  margin-top: 3rem;
  font-weight: 600;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: unset;
    font-size: 3.75rem;
  }
`;

export const Content = styled("article")`
  font-size: 1rem;
  font-weight: 400;
  color: ${Colors.ezGray};
  line-height: 180%;
`;

export const DesktopImageWrapper = styled("div")``;

export const DesktopImage = styled(GatsbyImage)`
  width: 100%;
  margin-top: 6.375rem;
  margin-bottom: 5.25rem;
  height: 480px;
  object-fit: cover;

  display: none !important;

  img {
    height: 480px !important;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: block !important;
  }
`;

export const TagsAndShareWrapper = styled("div")`
  font-weight: 400;
  font-size: 0.9375rem;
  color: ${Colors.ezGray};

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  /* >= 576px; */
  @media all and (min-width: ${Devices.bootstrapMobileBreakpoint}) {
    flex-direction: row;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 1.875rem;
    margin-bottom: 8.5rem;
  }
`;

export const TagsWrapper = styled("div")``;

export const TagLink = styled(Link)`
  color: ${Colors.ezGray};
  border-bottom: 1px solid transparent;
  &:focus {
    border-bottom: 1px solid ${Colors.sharpBlue};
  }
  &:hover,
  &:focus {
    color: ${Colors.sharpBlue};
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }
`;

export const ShareButtonsWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 0.875rem;

  /* >= 576px; */
  @media all and (min-width: ${Devices.bootstrapMobileBreakpoint}) {
    margin-top: unset;
  }
`;

export const ShareButtonsText = styled("div")`
  font-size: 1rem;
  font-weight: 500;
  color: ${Colors.ezBlack};
`;

export const ShareButtons = styled("div")``;

export const TagsTitle = styled("span")`
  font-size: 1rem;
  font-weight: 500;
  color: ${Colors.ezBlack};
  margin-right: 0.5625rem;
  margin-top: 2.875rem;
`;

export const MobileImageWrapper = styled("div")``;

export const MobileImage = styled(GatsbyImage)`
  width: 100%;
  margin-bottom: 2.5rem;
  height: 365px;
  object-fit: cover;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: none !important;
  }
`;
